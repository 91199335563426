import styled from "styled-components";

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#0a0803')};
    padding: 10px;
    min-height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    @media screen and (max-width: 1000px){
     padding: 10px 0;   
    };
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 10px;
    justify-content: center;
    overflow: hidden;
`;

export const InfoRow = styled.div`
    display: grid;
    flex-wrap: wrap;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 1000px) {
        grid-template-areas: 'col1' 'col2';  
        grid-template-columns: 1fr;
    };
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    margin-right: 0;
    padding: 0 10px;
    grid-area: col1;
    flex: 1;
    max-width: 100%;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 10px;
    grid-area: col2;
    flex: 1;
`;

export const TextWrapper = styled.div`
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 10px;
`;

export const TopLine = styled.p`
    font-family: 'Spinnaker';
    color: #d4af37;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    font-family: 'Cinzel';
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText})=>(lightText ? '#f7f8fa' : '#0a0803')};

    @media screen and (max-width: 480px){
        font-size: 32px;   
    };
`;

export const Subtitle = styled.p`
    max-width: 100%;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText})=>(darkText ? '#0a0803' : "#fff")};
    white-space: pre-line;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 100%;
    width: 100%;
    height: auto; /* Adjust height dynamically */
    overflow: hidden; /* Prevent overflow */
    display: flex; /* Center content */
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        max-width: 100%; /* Take full width on smaller screens */
    }
`;

export const Img = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain; /* Ensure image scales proportionally */
    max-width: 100%; /* Prevent image from exceeding its container */
    max-height: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;