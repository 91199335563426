import React from "react";
import styles from "./ContactFormElement"; // Import styles as a JavaScript object

const ContactForm = () => {
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <form
          method="POST"
          action="https://public.herotofu.com/v1/fc0e3130-d53b-11ef-a03f-4769c76878f0"
          style={styles.form}
        >
          <label style={styles.label}>
            <span style={styles.labelText}>Your name</span>
            <input
              type="text"
              name="name"
              style={styles.input}
              placeholder="Joe Bloggs"
            />
          </label>
          <label style={styles.label}>
            <span style={styles.labelText}>Email address</span>
            <input
              name="email"
              type="email"
              style={styles.input}
              placeholder="joe.bloggs@example.com"
              required
            />
          </label>
          <label style={styles.label}>
            <span style={styles.labelText}>Message</span>
            <textarea
              name="message"
              style={styles.textarea}
              rows="3"
              placeholder="Tell us what you're thinking about..."
            ></textarea>
          </label>
          <div style={styles.submitWrapper}>
            <button type="submit" style={styles.submitButton}>
              Contact Us
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
