import React from 'react';

const FindUs = () => {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          width="100%"
          height="800"
          min-height="100vh"
          id="map"
          src="https://maps.google.com/maps?q=golden%20needle%20kingston&t=&z=16&ie=UTF8&iwloc=&output=embed"
          title="Google Map"
        ></iframe>
      </div>
    </div>
  );
};

export default FindUs;
