const styles = {
    container: {
      width: "100%",
      maxWidth: "24rem", // Equivalent to md:w-96
      margin: "0 auto",
    },
    wrapper: {
      padding: "1.5rem", // p-6
    //   border: "1px solid #d1d5db", // border-gray-300
      border: "none",
      borderRadius: "0.375rem", // sm:rounded-md
    },
    label: {
      display: "block",
      marginBottom: "1.5rem", // mb-6
    },
    labelText: {
      display: "block",
      color: "#fff", // text-gray-700
      marginBottom: "0.25rem", // For spacing above the input
    },
    input: {
      display: "block",
      width: "100%",
      marginTop: "0.25rem", // mt-1
      padding: "0.5rem",
      border: "1px solid #d1d5db", // border-gray-300
      borderRadius: "0.375rem", // rounded-md
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)", // shadow-sm
      fontSize: "1rem",
      transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
    textarea: {
      display: "block",
      width: "100%",
      marginTop: "0.25rem", // mt-1
      padding: "0.5rem",
      border: "1px solid #d1d5db", // border-gray-300
      borderRadius: "0.375rem", // rounded-md
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)", // shadow-sm
      fontSize: "1rem",
      transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      resize: "vertical",
    },
    inputFocus: {
      borderColor: "#6366f1", // focus:border-indigo-300
      outline: "none",
      boxShadow: "0 0 0 3px rgba(99, 102, 241, 0.2)", // focus:ring-indigo-200 focus:ring-opacity-50
    },
    submitWrapper: {
      marginBottom: "1.5rem", // mb-6
    },
    submitButton: {
      height: "2.5rem", // h-10
      padding: "10px 22px", // px-5
      backgroundColor: "#d4af37",
      color: "#0a0803",
      borderRadius: "50px", // rounded-lg
      transition: "all 0.2s ease-in-out",
      cursor: "pointer",
      outline: "none",
      border: "none",
    },
    submitButtonHover: {
        background: "#fff",
        color: "#0a0803",
        transition: "all 0.2s ease-in-out",
        outline: "none",
        border: "none",
    },
    footer: {
      marginTop: "0.5rem", // mt-2
      textAlign: "right",
      color: "#374151", // text-gray-700
      fontSize: "0.75rem", // text-xs
    },
    footerLink: {
      textDecoration: "none",
      color: "inherit",
    },
  };
  
  export default styles;
  