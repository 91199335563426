import React, {useEffect, useState} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, NavLinksR } from './NavbarElements';

const Navbar = ({ toggle, activeSection }) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav = {scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}>GOLDEN NEEDLE</NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            {activeSection === 'home'? (
                            <NavLinks to='about'
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >About Us</NavLinks>) : (
                            <NavLinksR to="/#aboutSection"
                            >About Us</NavLinksR>)}
                        </NavItem>
                        <NavItem>
                            {activeSection === 'home'? (
                            <NavLinks to='map'
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Find Us</NavLinks>) : (
                            <NavLinksR to="/#mapSection"
                            >Find Us</NavLinksR>)}
                        </NavItem>
                        <NavItem>
                            {activeSection === 'home'? (
                            <NavLinks to='footerSection'
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >Contact Us</NavLinks>) : (
                            <NavLinksR to="/#footerSection"
                            >Contact Us</NavLinksR>)}
                        </NavItem>
                        <NavItem>
                            <NavLinksR to='https://widget.treatwell.co.uk/place/golden-needle-health-care-beauty/' 
                            target='_blank'
                            >Treatments</NavLinksR>
                        </NavItem>
                        <NavItem>
                            <NavLinksR to='https://giftup.app/place-order/88947639-f9a7-4f3d-985a-3dd5e4b46085'
                            target='_blank'
                            smooth={true} 
                            duration={500} 
                            spy={true} 
                            exact='true' 
                            offset={-80}
                            >Gift Card</NavLinksR>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to='https://widget.treatwell.co.uk/place/golden-needle-health-care-beauty/' 
                        target='_blank'
                        >Book Now</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    );
};

export default Navbar;
