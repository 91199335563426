import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjAbout } from '../components/InfoSection/Data';
import Footer from '../components/Footer';
import FindUs from '../components/FindUs';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} activeSection={'home'}/>
            <Navbar toggle={toggle} activeSection={'home'} />
            <div id="heroSection">
                <HeroSection />
            </div>
            <div id="aboutSection">
                <InfoSection {...homeObjAbout}/> 
            </div>
            <div  id="mapSection">
                <FindUs />
            </div>
            <div id="footerSection">
                <Footer />
            </div>
        </>
    );
};

export default Home;
