export const homeObjAbout = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    headline: 'GOLDEN NEEDLE HEALTH CARE & BEAUTY',
    topLine: 'A traditional Chinese medical clinic',
    description: 'Traditional Chinese Medicine (TCM) is an ancient healing system that focuses on restoring balance and promoting well-being through practices like acupuncture and acupressure. \n\nAcupuncture uses fine needles to stimulate specific points on the body, improving circulation, reducing inflammation, and activating the body’s natural pain-relief mechanisms, similar to modern pain management techniques. \n\nAcupressure applies manual pressure to the same points to alleviate muscle tension, enhance blood flow, and encourage relaxation, much like massage therapy. Both practices aim to optimize the body\'s natural healing abilities, offering effective alternatives or complements to conventional treatments.\n\nGolden Needle, established in 2016, has been serving the Kingston upon Thames community with expert TCM care. \n\nLed by Zhang, a skilled practitioner with over 30 years of experience, Golden Needle specializes in acupuncture, massage, cupping, and herbal medicine. Zhang treats a wide range of conditions, including infertility, women\'s health issues, depression, asthma, and pain management, earning a strong reputation for professionalism and dedication.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/shop_front.jpg'),
    alt: 'acupuncture', 
    dark: false,
    primary: true,
    darkText: true
}