import styled from "styled-components";
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
    background-color: #0a0803;
    min-height: 100vh;
    padding: 50px 0;
`;

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Make sure the footer links wrap on smaller screens */
    gap: 16px; /* Add some space between the FooterLinkItems */

    @media screen and (max-width: 820px){
        padding-top: 32px;        
        flex-direction: column;
    };
`;

export const FooterLinksWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 820px){   
        flex-direction: column;
        width: 100%;
    };
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    flex: 1;
    min-width: 160px;
    box-sizing: border-box;
    color: #fff;
    word-wrap: break-word;

    @media screen and (max-width: 420px){
        margin: 0;
        padding: 10px;
        width: 100%;
    };
`;

export const FooterLinkTitle = styled.h1`
    font-size: 1.2rem;
    margin-bottom: 16px;
    word-wrap: break-word;
`;

export const FooterLinkParagraph = styled.p`
    font-size: 1rem;
    margin-bottom: 6px;
    word-wrap: break-word;
    max-width: 200px; /* Ensuring text doesn't overflow */
    line-height: 1.5;
`;

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    word-wrap: break-word;

    &:hover {
        color: #d4af37;
        transition: 0.3s ease-out;
    };
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px){
     flex-direction: column;   
    };
`;

export const SocialLogo = styled(Link)`
    color: #fff;
    font-family: 'Cinzel', serif;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
`;